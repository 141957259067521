import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

import { ARRK_BASE_API } from 'src/constants';

class AuthService {
    setAxiosInterceptors = ({ onLogout }) => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (
                    error.response &&
                    (error.response.status === 401 ||
                        error.response.status === 403)
                ) {
                    console.log(onLogout)
                    if (onLogout) {
                        console.log("Calling logout")
                        onLogout();
                    }
                }
                return Promise.reject(error);
            }
        );
    };
    checkCaptchaToken = (token) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/users/captcha`, {
                    token: token
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    register = (registration) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/users/register`, registration, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    //console.log(response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {});
        });
    };

    loginWithCode = (code) =>
        new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/azure/gettoken`, {
                    params: {
                        code
                    },
                    withCredentials: true
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });

    loginInWithToken = () =>
        new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/me`)
                .then(async (response) => {
                    if (response.data.data.user) {
                        resolve(response.data.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    logout = () => {
        new Promise((resolve, reject) => {
            let config = {};
            axios
                .get(`${ARRK_BASE_API}/users/logout`, config)
                .then((response) => {
                    resolve(response.data.msg);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    requestItarToken = (verify) =>
        new Promise((resolve, reject) => {
            let config = {};
            if (verify) {
                config = {
                    params: {
                        verify
                    }
                };
            }
            axios
                .get(`${ARRK_BASE_API}/users/itartoken`, config)
                .then((response) => {
                    if (!response.data.iserror) {
                        resolve(response.data.msg);
                        if (verify) {
                            localStorage.setItem(
                                'itrsession',
                                response.data.data
                            );
                        }
                    } else {
                        reject(response.data.msg);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    checkItarSession = (itrsession) =>
        new Promise((resolve, reject) => {
            let config = {};
            if (itrsession) {
                config = {
                    params: {
                        itrsession
                    }
                };
            }
            axios
                .get(`${ARRK_BASE_API}/users/itarsession`, config)
                .then((response) => {
                    if (!response.data.iserror) {
                        resolve(response.data.msg);
                    } else {
                        reject(response.data.msg);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;
        return decoded.exp > currentTime;
    };
}

const authService = new AuthService();
export default authService;
