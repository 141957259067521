import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from 'react-router';

function DashBoardGuard({ children }) {
    const { user } = useSelector((state) => state.account);
    const location = useLocation();
    if (!user) {
        //console.log(user, location);

        localStorage.removeItem('accessToken');
        localStorage.removeItem('itrsesion');
        delete axios.defaults.headers.common.Authorization;
        return <Redirect to="/login" />;
    }

    if (
        (user.role === 'pe' || user.role === 'manager') &&
        location.pathname !== '/pe/dashboard'
    ) {
        return <Redirect to="/pe/dashboard" />;
    }

    if (user.role === 'admin' && location.pathname !== '/admin/dashboard') {
        return <Redirect to="/admin/dashboard" />;
    }
    //console.log(user.role, location.pathname);

    return children;
}

DashBoardGuard.propTypes = {
    children: PropTypes.any
};

export default DashBoardGuard;
