import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function AdminGuard({ children }) {
    const { user } = useSelector((state) => state.account);
    //console.log(user);
    const location = useLocation()
    console.log("Admin Guard on ", location.pathname)
    if (user && user.role !== 'admin') {
        return <Redirect to="/" />;
    }
    return children;
}

AdminGuard.propTypes = {
    children: PropTypes.any
};

export default AdminGuard;
