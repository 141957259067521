import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
//import ItarGuard from 'src/components/ItarGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminGuard from './components/AdminGuard';
import PEGuard from './components/PEGuard';
import DashBoardGuard from './components/DashBoardGuard';

import ManagerGuard from './components/ManagerGuard';
import DeveloperGuard from './components/DeveloperGuard';
import UserGuard from './components/UserGuard';


const routesConfig = [
    {
        exact: true,
        //  guard: RootaGuard,
        path: '/',

        component: () => <Redirect to="/app" />
    },
    {
        exact: true,
        path: '/redirect',
        component: lazy(() => import('src/views/auth/RedirectView'))
    },
    {
        exact: true,
        path: '/itarmfa',
        component: lazy(() => import('src/views/auth/ItarmfaView'))
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/pages/Error404View'))
    },
    {
        exact: true,
        guard: AuthGuard,
        path: '/forbidden',
        component: lazy(() => import('src/views/pages/ForbiddenView'))
    },
    {
        exact: true,
        guard: AuthGuard,
        path: '/pending',
        component: lazy(() => import('src/views/pages/ArrkAccount'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
        exact: true,
        path: '/register-unprotected',
        component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
              exact: true,
              path: '/app/complete/:subject/:object',
              component: lazy( () => import('src/views/pages/CompleteView'))
            },
            {
                exact: true,
                path: '/app/requests/new',
                component: lazy(() =>
                    import('src/views/pages/RequestsView/New')
                )
            },
            {
                exact: true,
                path: '/app/',
                component: () => <Redirect to="/app/dashboard" />
            },

            {
                exact: true,
                path: '/app/dashboard',
                guard: DashBoardGuard,
                component: lazy(() => import('src/views/reports/DashboardView'))
            },

            {
                exact: true,
                guard: UserGuard,

                path: '/app/management/requests',
                component: lazy(() =>
                    import('src/views/management/RequestsListView')
                )
            },

            {
                exact: true,
                guard: UserGuard,
                path: '/app/management/quote/:quoteid',
                component: lazy(() =>
                    import('src/views/management/QuoteDetailsView')
                )
            },
            {
                exact: true,
                path: '/app/management/customers/:customerId/edit',
                component: lazy(() =>
                    import('src/views/management/CustomerEditView')
                )
            },

            {
                exact: true,
                guard: UserGuard,

                path: '/app/management/orders',
                component: lazy(() =>
                    import('src/views/management/QuotesListView')
                )
            },
            {
                exact: true,
                guard: UserGuard,

                path: '/app/management/orders/:ordernum/reorder',
                component: lazy(() =>
                    import('src/views/management/ReorderView')
                )
            },

            {
                exact: true,
                path: '/app/management/notifications',
                component: lazy(() =>
                    import('src/views/management/NotificationListView')
                )
            },
            {
                exact: true,
                path: '/app/management/notifications/:notiid',
                component: lazy(() =>
                    import('src/views/management/NotificationListView/Preview')
                )
            },
            {
                exact: true,
                guard: UserGuard,

                path: '/app/management/request/:requestid',
                component: lazy(() =>
                    import('src/views/management/RequestDetailsView')
                )
            },
            {
                exact: true,
                guard: UserGuard,

                path: '/app/management/accept/:requestid',
                component: lazy(() =>
                    import(
                        'src/views/management/RequestDetailsView/AcceptWizard'
                    )
                )
            },
            {
                exact: true,
                path: '/app/management/itar/request/:requestid',
                //  guard: ItarGuard,
                component: lazy(() =>
                    import('src/views/management/RequestDetailsView')
                )
            },
            {
                guard: UserGuard,

                exact: true,
                path: '/app/management/invoices',
                component: lazy(() =>
                    import('src/views/management/InvoiceListView')
                )
            },
            {
                guard: UserGuard,

                exact: true,
                path: '/app/management/invoice/:invoiceid',
                component: lazy(() =>
                    import('src/views/management/InvoiceDetailsView')
                )
            },
            {
                guard: UserGuard,

                exact: true,
                path: '/app/management',
                component: () => <Redirect to="/app/management/customers" />
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
    {
        path: '/developer',
        guard: DeveloperGuard,
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/developer',
                component: lazy(() => import('src/views/pages/DeveloperView'))
            }
        ]
    },
    {
        path: '/admin',
        guard: AdminGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/admin/dashboard',
                component: lazy(() =>
                                          import('src/views/admin/AdminDashBoardView'))

            },
            {
                exact: true,
                path: '/admin/users',
                guard: AdminGuard,
                component: lazy(() =>
                    import('src/views/admin/AdminUsersListView')
                )
            },

            {
                exact: true,
                path: '/admin/request/:requestid',
                component: lazy(() =>
                    import('src/views/admin/AdminRequestDetailView')
                )
            },
            {
                exact: true,
                path: '/admin/users/:userid',
                component: lazy(() =>
                    import('src/views/admin/AdminUsersDetailsView')
                )
            },
            {
                exact: true,
                path: '/admin/requests',
                component: lazy(() =>
                    import('src/views/admin/AdminRequestsListView')
                )
            },
            {
                exact: true,
                path: '/admin/preinvoice/:invoiceid',
                component: lazy(() => import('src/views/admin/PreInvoiceView'))
            },
            {
                exact: true,
                path: '/admin/settings',
                guard: AdminGuard,
                component: lazy(() =>
                    import('src/views/admin/AdminSettingsView')
                )
            },
            {
                exact: true,
                path: '/admin/sync',
                guard: AdminGuard,
                component: lazy(() =>
                                    import('src/views/admin/AdminSyncView')
                )
            },
            {
                path: '*',
                routes: [
                    {
                        component: () => <Redirect to="/admin/dashboard" />
                    }
                ]
            }
        ]
    },
    {
        path: '/pe',
        guard: PEGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                guard: DashBoardGuard,
                path: '/pe/dashboard',
                component: lazy(() => import('src/views/pe/PEDashBoardView'))
            },
            {
                exact: true,
                path: '/pe/users',
                component: lazy(() => import('src/views/pe/PEUsersListView'))
            },
            {
                exact: true,
                path: '/pe/users/:userid',
                component: lazy(() =>
                    import('src/views/pe/PEUsersListView/PEUserDetailView')
                )
            },
            {
                exact: true,
                path: '/pe/requests',
                component: lazy(() => import('src/views/pe/PERequestsListView'))
            },
            {
                exact: true,
                path: '/pe/request/:requestid',
                component: lazy(() =>
                    import('src/views/admin/AdminRequestDetailView')
                )
            },

            {
                path: '*',
                routes: [
                    {
                        component: () => <Redirect to="/pe/dashboard" />
                    }
                ]
            }
        ]
    },
    {
        path: '/manager',
        guard: ManagerGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                guard: DashBoardGuard,
                path: '/manager/dashboard',
                component: lazy(() =>
                    import('src/views/manager/ManagerDashboardView')
                )
            },
            {
                exact: true,
                path: '/manager/reports/summary',
                component: lazy(() =>
                                    import('src/views/manager/SummaryReportView')
                )
            },
            {
                exact: true,
                path: '/manager/reports/quotes',
                component: lazy(() =>
                    import('src/views/manager/QuotesReportView')
                )
            },
            {
                exact: true,
                path: '/manager/reports/cancellations',
                component: lazy(() =>
                    import('src/views/manager/CancellationsReportView')
                )
            },
            {
                exact: true,
                path: '/manager/prequote/:quoteid',
                component: lazy(() => import('src/views/manager/PreQuoteView'))
            },

            {
                path: '*',
                routes: [
                    {
                        component: () => <Redirect to="/manager/dashboard" />
                    }
                ]
            }
        ]
    },
    {
        exact: true,
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/account',
        component: lazy(() => import('src/views/pages/AccountView'))
    },
    {
        path: '/docs',
        layout: DocsLayout,
        guard: GuestGuard,
        routes: [
            {
                exact: true,
                path: '/docs',
                component: () => <Redirect to="/docs/welcome" />
            },
            {
                exact: true,
                path: '/docs/welcome',

                component: lazy(() => import('src/views/docs/WelcomeView'))
            },
            {
                exact: true,
                path: '/docs/profile',
                component: lazy(() =>
                    import('src/views/docs/ProfileView')
                )
            },
            {
                exact: true,
                path: '/docs/addresses',
                component: lazy(() =>
                                    import('src/views/docs/AddressesView')
                )
            },
            {
                exact: true,
                path: '/docs/requestquote',
                component: lazy(() =>
                                    import('src/views/docs/RequestQuoteView')
                )
            },
            {
                exact: true,
                path: '/docs/cancellations',
                component: lazy(() =>
                                    import('src/views/docs/CancellationsView')
                )
            },
            {
                exact: true,
                path: '/docs/approvequote',
                component: lazy(() =>
                                    import('src/views/docs/ApproveQuoteView')
                )
            },
            {
                exact: true,
                path: '/docs/environment-variables',
                component: lazy(() =>
                    import('src/views/docs/EnvironmentVariablesView')
                )
            },
            {
                exact: true,
                path: '/docs/deployment',
                component: lazy(() => import('src/views/docs/DeploymentView'))
            },
            {
                exact: true,
                path: '/docs/api-calls',
                component: lazy(() => import('src/views/docs/ApiCallsView'))
            },
            {
                exact: true,
                path: '/docs/analytics',
                component: lazy(() => import('src/views/docs/AnalyticsView'))
            },
            {
                exact: true,
                path: '/docs/authentication',
                component: lazy(() =>
                    import('src/views/docs/AuthenticationView')
                )
            },
            {
                exact: true,
                path: '/docs/routing',
                component: lazy(() => import('src/views/docs/RoutingView'))
            },
            {
                exact: true,
                path: '/docs/settings',
                component: lazy(() => import('src/views/docs/SettingsView'))
            },
            {
                exact: true,
                path: '/docs/state-management',
                component: lazy(() =>
                    import('src/views/docs/StateManagementView')
                )
            },
            {
                exact: true,
                path: '/docs/theming',
                component: lazy(() => import('src/views/docs/ThemingView'))
            },
            {
                exact: true,
                path: '/docs/support',
                component: lazy(() => import('src/views/docs/SupportView'))
            },
            {
                exact: true,
                path: '/docs/tos',
                guard: GuestGuard,

                component: lazy(() => import('src/views/docs/TosView'))
            },
            {
                exact: true,
                path: '/docs/nda',
                guard: GuestGuard,

                component: lazy(() => import('src/views/docs/NDAView'))
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

const renderRoutes = (routes) =>
    routes ? (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
