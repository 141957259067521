import axios from 'src/utils/axios';
import { ARRK_BASE_API } from 'src/constants';

class AccountService {

    setComplete = (actiontype) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/users/complete/${actiontype}`, {})
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    }
    assignTask = (activity, user) => {
        let params = {
            taskid: activity.id
        };
        if (user) {
            params = {
                ...params,
                userid: user.id
            };
        }
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/tasks/assign`, {
                    params
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    listUsers = (role) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/list`, {
                    params: {
                        role
                    }
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getTasks = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/tasks`, {
                    params: paginate
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    getTaskStats = (period) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/tasks/stats`, {
                    params: period
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    getQuoteStats = (period) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/quotes/stats`, {
                    params: period
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    getManagerStats = (range) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/manager/stats`, {
                    params: range
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    getUserRequests = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/getrequests`, {
                    params: paginate
                })
                .then((response) => {
                    //console.log(response);
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    getAdminRequests = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/quotes`, {
                    params: paginate
                })
                .then((response) => {
                    //console.log(response);
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    getUserInvoices = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/getinvoices`, {
                    params: paginate
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    getUserOrders = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/getorders`, {
                    params: paginate
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    getUserOrders = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/getorders`, {
                    params: paginate
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    getUserNotifications = (paginate) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/notifications`, {
                    params: paginate
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };

    getNotification = (notiid) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/notifications/${notiid}`, {
                    params: {
                        notiid
                    }
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    getUserAddresses = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/getaddresses`, {})
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    addUserAddress = (address) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/addresses/add`, address)
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    deleteUserAddress = (addressid) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/addresses/delete`, {
                    params: {
                        addressid
                    }
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    markRequestTask = (taskid, action, note) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/tasks/mark`, {
                    taskid,
                    action,
                    note
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    inviteUsers = (users, role) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/admin/users/invite`, {
                    users,
                    role
                })
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    sendMessage = (message) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/messages/add`, message)
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    setUserAvatar = (avatar) => {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('avatar', avatar);
            axios
                .post(`${ARRK_BASE_API}/users/avatar`, formData)
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    setUserCompany = (company) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/users/company`, company)
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    setUserNotificationPreferences = (settings) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/users/setpreferences`, settings)
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    removeUserAvatar = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${ARRK_BASE_API}/users/avatar`,
                    {},
                    {
                        params: {
                            remove: true
                        }
                    }
                )
                .then((response) => {
                    resolve(response.data.data ? response.data.data : {});
                })
                .catch((error) => {
                    //console.log(error);
                    reject(error);
                });
        });
    };
    setShippingAddress(orderid, shippingaddressid) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/requests/setshipping`, {
                    params: {
                        orderid,
                        shippingaddressid
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    //Admin
    assignRequest = (requestid, peid) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/admin/requestassign`, {
                    params: {
                        requestid,
                        peid
                    }
                })
                .then((response) => {
                    //console.log(response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    setOppId = (oppid, requestid) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/admin/assignopp`, {
                    params: {
                        oppid,
                        requestid
                    }
                })
                .then((response) => {
                    //console.log(response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    acceptEstimate = (estimateid, billto, shipto) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/quotes/accept`, {
                    params: {
                        estimateid,
                        billto,
                        shipto
                    }
                })
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    cancelOrder = (orderid, requesttype, reasontype, reasontext) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/quotes/cancel`, {
                    params: {
                        orderid,
                        requesttype,
                        reasontype,
                        reasontext
                    }
                })
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    getSettings = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/settings`, {})
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    requestUpdate = (model, recordid) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/updates/request`, {
                    params: {
                        model: model || '',
                        recordid: recordid || ''
                    }
                })
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    setSettings = (settings) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/settings`, settings)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    setInstructions = (requestid, instructions) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ARRK_BASE_API}/requests/editinstructions`, {
                    requestid,
                    instructions
                })
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    downloadAttachment = (eid, filename) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${ARRK_BASE_API}/users/attachment/${eid}`, {
                    params: {
                        eid
                    },
                    responseType: 'blob'
                })
                .then(({ data }) => {
                    //console.log(data);
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([data])
                    );
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', filename); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

const accountService = new AccountService();
export default accountService;
