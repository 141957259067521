import axios from 'src/utils/axios';
import { ARRK_BASE_API } from 'src/constants';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export function getNotifications() {
    const request = axios.get(`${ARRK_BASE_API}/users/getnotifications`);
    return (dispatch) => {
        request.then((response) =>
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: response.data
            })
        );
    };
}
