/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    Chip,
    ListSubheader,
    Typography,
    makeStyles
} from '@material-ui/core';

import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import UserNavConfig from './UserNavBarItems';
import PENavConfig from './PENavBarItems';
import PEToolingNavConfig from './PEToolingNavBarItems'
import AdminNavConfig from './AdminNavBarItems';
import ManagerNavConfig from './ManagerNavBarItems';

import canview from 'src/utils/canview';

function renderNavItems({ items, ...rest }) {
    return (
        <List disablePadding>
            {items.reduce((acc, item) => {
                return reduceChildRoutes({ acc, item, ...rest });
            }, [])}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
    const key = item.title + depth;
    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: "false"
        });
        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
                exact="false"
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

function NavBar({ openMobile, onMobileClose }) {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useSelector((state) => state.account);
    const [navConfig, setNavConfig] = useState([]);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        if (canview.isUser(user)) {
            setNavConfig(UserNavConfig);
        }
        if (canview.isPE(user) && user.istooling === false) {
            setNavConfig(PENavConfig);
        }

        if (canview.isPE(user) && user.istooling === true) {
            setNavConfig(PEToolingNavConfig);
        }
        if (canview.isManager(user)) {
            //console.log('Setting Manager config');
            //console.log(ManagerNavConfig);
            setNavConfig(ManagerNavConfig);
        }
        if (canview.isAdmin(user)) {
            setNavConfig(AdminNavConfig);
        }
        if (canview.isRoot(user)) {
            setNavConfig(AdminNavConfig);
        }
        //console.log(navConfig);
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/" >
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box display="flex" justifyContent="center">
                        <RouterLink to="/account">
                            <Avatar
                                alt="User"
                                className={classes.avatar}
                                src={user.avatar}
                            />
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Link
                            component={RouterLink}
                            to="/account"
                            variant="h5"
                            exact="false"
                            color="textPrimary"
                            underline="none"
                        >
                            {`${user.firstname} ${user.lastname}`}
                        </Link>
                        <Typography variant="body2" color="textSecondary">
                            {user.jobtitle}
                        </Typography>
                        <br />
                        {user.role === 'admin' && (
                            <Chip
                                variant="outlined"
                                size="small"
                                label="Administrator"
                                color="secondary"
                            />
                        )}
                        {user.role === 'pe' && (
                            <Chip
                                variant="outlined"
                                size="small"
                                label="Project Engineer"
                                color="secondary"
                            />
                        )}
                        {user.role === 'manager' && (
                            <Chip
                                variant="outlined"
                                size="small"
                                label="Manager"
                                color="secondary"
                            />
                        )}
                        {user.role === 'root' && (
                            <Chip
                                variant="outlined"
                                size="small"
                                label="Root Account"
                                color="primary"
                            />
                        )}
                    </Box>
                </Box>
                <Divider />
                {user.role === 'user' && (
                    <Box p={2}>
                        <Box mt={2} textAlign="left">
                            <Link
                                component={RouterLink}
                                to="/docs/tos"
                                variant="body2"
                                color="textPrimary"
                                underline="none"
                            >
                                Terms & Conditions
                            </Link>
                        </Box>
                        <Box mt={2} textAlign="left">

                            <Link
                                component={RouterLink}
                                to="/docs/nda"
                                variant="body2"
                                color="textPrimary"
                                underline="none"
                            >
                                Non-Disclosure Agreement
                            </Link>
                        </Box>
                        {/*<Box mt={2} textAlign="left">
                            <Link
                                component={RouterLink}
                                to="/account"
                                variant="body2"
                                color="textPrimary"
                                underline="none"
                            >
                                BDM Information
                            </Link>
                        </Box> */}
                        <Box mt={2} textAlign="left">
                            <Link
                                component={RouterLink}
                                onClick={() => {
                                    window.location = 'https://www.arrk.com/';
                                }}
                                variant="body2"
                                color="textPrimary"
                                underline="none"
                                to="#"
                            >
                                Go to Our Main Page
                            </Link>
                        </Box>
                    </Box>
                )}
                <Box p={2}>
                    {navConfig &&
                        navConfig.map((config) => {
                            if (
                                config.canview &&
                                !config.canview.includes(user.role)
                            ) {
                                return false;
                            }
                            return (
                                <List
                                    key={config.subheader}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {config.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: config.items,
                                        pathname: location.pathname
                                    })}
                                </List>
                            );
                        })}
                </Box>
                <Divider />

                    <Box p={2}>
                        <Box
                            p={2}
                            borderRadius="borderRadius"
                            bgcolor="background.dark"
                        >
                            <Typography variant="h6" color="textPrimary">
                                Need Help ?
                            </Typography>
                            <Link
                                variant="subtitle1"
                                color="secondary"
                                component={RouterLink}
                                to="/docs"
                                exact={"false"}
                            >
                                Online Help
                            </Link>
                        </Box>
                    </Box>
                    <Box p={2}>
                            <Typography variant="h6" color="textPrimary">
                                v.{process.env.REACT_APP_VERSION}
                            </Typography>
                                    </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
