import React from 'react';

function Logo(props) {
    return (
        <img
            style={{
                width: '101px',
                height: '31px'
            }}
            alt="Logo"
            src="/static/logo.png"
            {...props}
        />
    );
}

export default Logo;
