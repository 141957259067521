function isUser(user) {
    return is(user, 'user');
}
function isPE(user) {
    return is(user, 'pe');
}
function isManager(user) {
    return is(user, 'manager');
}

function isAdmin(user) {
    return is(user, 'admin');
}
function isRoot(user) {
    return is(user, 'root');
}
function isPowerUser(user) {
    return is(user, 'root') || is(user, 'admin') || is(user, 'manager');
}
function is(user, role) {
    return user && user.role && user.role === role;
}

export default {
    isUser,
    isPE,
    isManager,
    isAdmin,
    isRoot,
    isPowerUser
};
