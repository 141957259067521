import React, { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Pusher from 'pusher-js';
import accountService from 'src/services/accountService';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3)
    }
}));

function InforUpdate({
    silent,
    model,
    recordid,
    open,
    onClose,
    onUpdate,
    ...rest
}) {
    const classes = useStyles();
    const [status, setStatus] = useState('Please wait ...');
    const [doSilent, setDoSilent] = useState(silent);
    // TODO: Send Pusher config to .env file

    const getUpdates = async () => {
        try {
            console.log('getUpdates', process.env.REACT_APP_ARRK_PUSHER_APP_KEY)
            const pusher = new Pusher(process.env.REACT_APP_ARRK_PUSHER_APP_KEY, {
                cluster: 'us3'
            });
            const uniq = await accountService.requestUpdate(model, recordid);
            if (uniq) {
                const channel = pusher.subscribe(uniq);
                channel.bind(
                    'update-progress',
                    await function (data) {
                        console.log(data);
                        if (data && data.status) {
                            setStatus(data.status);
                            if (data.status === 'Fetching done') {
                                if (doSilent === true) {
                                }
                                if (onClose) {
                                    setStatus('Please wait ...');
                                    onClose();
                                }
                            }
                        }
                    }
                );
            } else {
                if (onClose) {
                    setStatus('Please wait ...');
                    onClose();
                }
            }
        } catch (error) {
            //console.log(error);
        } finally {
        }
    };

    useEffect(() => {
        if (doSilent === true) {
            getUpdates().then();
        }
        return () => {
            setDoSilent(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (open === false) {
            return;
        }

        setStatus('Please wait ...');

        NProgress.start();

        getUpdates().then((r) => {});

        return () => {
            NProgress.done();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    return (
        <>
            <Dialog open={open}>
                <DialogContent>
                    <div className={classes.root}>
                        <Box width={400}>
                            <LinearProgress />
                            <br />
                            <Typography
                                align={'center'}
                                variant="body2"
                                color="textSecondary"
                            >
                                {status}
                            </Typography>
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InforUpdate;
