import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Notifications from './Notifications';
import ArrkInfo from 'src/components/ArrkInfo';
import InforUpdate from '../../../components/InforUpdate';
import SyncIcon from '@material-ui/icons/Sync';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: 'none',
                  // backgroundColor: theme.palette.primary.main
                  backgroundColor: '#E7F1FB'
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default
              }
            : {})
    },
    toolbar: {
        minHeight: 64
    }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
    const account = useSelector((state) => state.account);
    const classes = useStyles();
    const [doSync, setDoSync] = useState(false);
    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton
                        className={classes.menuButton}
                        onClick={onMobileNavOpen}
                    >
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to="/">
                        <Logo />
                    </RouterLink>
                </Hidden>
                <Box ml={2} flexGrow={1} />
                <ArrkInfo />
                &nbsp; &nbsp;
                {account && account.user.role !== 'user' && (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => setDoSync(true)}
                            startIcon={<SyncIcon />}
                        >
                            Sync Infor Syteline changes
                        </Button>
                        <InforUpdate
                            open={doSync}
                            onClose={() => setDoSync(false)}
                        />{' '}
                    </>
                )}
                <Notifications />
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
