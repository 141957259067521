import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
    User as UserIcon,
    PieChart as PieChartIcon,
    Users as UsersIcon
} from 'react-feather';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import CancelScheduleSendOutlinedIcon from '@material-ui/icons/CancelScheduleSendOutlined';
import WebOutlinedIcon from '@material-ui/icons/Web';

const ManagerNavConfig = [
    {
        canview: ['admin', 'pe', 'manager'],
        subheader: 'Main',
        items: [
            {
                title: 'Dashboard',
                icon: PieChartIcon,
                href: '/manager/dashboard'
            }
        ]
    },
    {
        canview: ['manager'],
        subheader: 'Manage',
        items: [
            {
                title: 'Users',
                icon: UsersIcon,
                href: '/pe/users'
            },
            {
                title: 'Web requests',
                icon: ReceiptIcon,
                href: '/pe/requests'
            }
        ]
    },

    {
        canview: ['admin', 'manager', 'manager'],
        subheader: 'Reports',
        items: [
            {
                title: 'Summary',
                icon: WebOutlinedIcon,
                href: '/manager/reports/summary'
            },
            {
                title: 'Quotes',
                icon: InsertChartOutlinedOutlinedIcon,
                href: '/manager/reports/quotes'
            },
            {
                title: 'Cancellations',
                icon: CancelScheduleSendOutlinedIcon,
                href: '/manager/reports/cancellations'
            }
        ]
    },

    {
        canview: ['user', 'admin', 'pe', 'manager'],
        subheader: 'Settings',
        href: '/app/pages',
        items: [
            {
                title: 'Account',
                href: '/account',
                icon: UserIcon
            }
        ]
    }
];

export default ManagerNavConfig;
