import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
    User as UserIcon,
    PieChart as PieChartIcon,
    Users as UsersIcon
} from 'react-feather';

const PENavConfig = [
    {
        canview: ['pe'],
        subheader: 'Main',
        items: [
            {
                title: 'Dashboard',
                icon: PieChartIcon,
                href: '/pe/dashboard'
            }
        ]
    },


    {
        canview: ['admin', 'root'],
        subheader: 'Manage',
        items: [
            {
                title: 'Users',
                icon: UsersIcon,
                href: '/pe/users'
            },
            {
                title: 'Web Requests',
                icon: ReceiptIcon,
                href: '/pe/requests'
            }
        ]
    },
    {
        canview: ['user', 'admin', 'manager'],
        subheader: 'Settings',
        href: '/app/pages',
        items: [
            {
                title: 'Account',
                href: '/account',
                icon: UserIcon
            }
        ]
    }
];
export default PENavConfig;
