import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import {
    setUserData,
    setUserCompleteness,
    logout
} from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { useLocation } from 'react-router';
import useIsMountedRef from '../hooks/useIsMountedRef';

function Auth({ children }) {
    const [isLoading, setLoading] = useState(true);
    let { pathname } = useLocation();
    const dispatch = useDispatch();
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        const initAuth = async () => {
            if (pathname !== '/login') {
                authService.setAxiosInterceptors({
                    onLogout: () =>  {
                        dispatch(logout())
                    }
                });
                try {
                    if (isMountedRef.current) {
                        const userdata = await authService.loginInWithToken();
                        const user = userdata.user;
                        await dispatch(setUserData(user));
                        await dispatch(setUserCompleteness(userdata.personalpct, userdata.companypct));
                    }
                } catch (error) {
                    console.log(error.message)
                    //dispatch(logout());
                } finally {
                    setLoading(false);
                }
            } else {
                await authService.logout();
            }
        };
        if (pathname !== '/docs/nda' && pathname !== '/docs/tos' ) {
            initAuth();
        }
        setLoading(false);
    }, [dispatch, pathname, isMountedRef]);

    if (isLoading) {
        return <SplashScreen />;
    }
    return children;
}

Auth.propTypes = {
    children: PropTypes.any
};

export default Auth;
