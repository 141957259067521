import {  useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import * as axios from 'lodash';

function  AuthGuard({ children }) {
    // Pull a user me
   /* const dispatch = useDispatch()
    const init = async () => {
        const userdata = await authService.loginInWithToken();
        const user = userdata.user;
        await dispatch(setUserData(user));

    } */
    const history = useHistory()
    const account = useSelector((state) => state.account);
    const location = useLocation();
        if (!account.user) {
            try {
                delete axios.defaults.headers.common.Authorization;
                // Set A Cookie for when Returning to login.
                if (location.pathname !== "/login") {
                    localStorage.setItem('redirect', location.pathname);
                }
            } catch {

            }
            history.push("/login")
            return null
        }
        const redir = localStorage.getItem('redirect');
       if (redir !== null) {

          localStorage.removeItem('redirect');
          return history.push(redir)
       }
        return children;



}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
