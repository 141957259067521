import axiosInstance from 'src/utils/axios';
import axios from 'axios';
import { ARRK_BASE_API } from 'src/constants';

class NotificationsService {
    setAxiosInterceptors = ({ onLogout }) => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (
                    error.response &&
                    (error.response.status === 401 ||
                        error.response.status === 403)
                ) {
                    this.setSession(null);
                    localStorage.removeItem('itrsession');
                    if (onLogout) {
                        onLogout();
                    }
                }
                return Promise.reject(error);
            }
        );
    };
    markAsRead(ids) {
        new Promise((resolve, reject) => {
            axiosInstance
                .get(`${ARRK_BASE_API}/notifications/read`, {
                    params: {
                        ids
                    }
                })
                .then((response) => {
                    if (!response.data.iserror) {
                        resolve(response.data.data);
                    } else {
                        reject(response.data.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

const notificationsService = new NotificationsService();
export default notificationsService;
