/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    SILENT_LOGIN,
    UPDATE_PROFILE,
    PROFILE_COMPLETENESS
} from 'src/actions/accountActions';

const initialState = {
    user: null ,
    completeness: 0,
    personalpct: 0,
    companypct: 0,
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.user = null;
            });
        }

        case LOGIN_SUCCESS: {
            const { user } = action.payload;

            return produce(state, (draft) => {
                draft.user = user;
            });
        }
        case PROFILE_COMPLETENESS: {
            const { personalpct, companypct } = action.payload;
            return produce(state, (draft) => {
                draft.personalpct = personalpct;
                draft.companypct = companypct;
            });
        }

        case LOGIN_FAILURE: {
            return produce(state, () => {
                // Maybe store error
            });
        }
        case LOGOUT: {
            return produce(state, (draft) => {
                draft.user = null;
            });
        }

        case SILENT_LOGIN: {
            const { user } = action.payload;

            return produce(state, (draft) => {
                draft.user = user;
            });
        }
        case UPDATE_PROFILE: {
            const user = action.payload;
            return produce(state, (draft) => {
                draft.user = user;
            });
        }

        default: {
            return state;
        }
    }
};

export default accountReducer;
