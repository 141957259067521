import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function UserGuard({ children }) {
    const { user } = useSelector((state) => state.account);
    if (user && user.role !== 'user' && user.role !== 'admin') {
        return <Redirect to="/" />;
    }
    return children;
}

UserGuard.propTypes = {
    children: PropTypes.any
};

export default UserGuard;
