import React from 'react';

import { Backdrop, Box, makeStyles, Typography } from '@material-ui/core';
import Logo from './Logo';
const useStyles = makeStyles((theme) => ({
    root: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#E7F1FB',
        backgroundColor: 'rgb(231, 241, 251,0.5)'
    },
    action: {
        marginBottom: theme.spacing(1),
        '& + &': {
            marginLeft: theme.spacing(1)
        }
    },
    actionIcon: {
        marginRight: theme.spacing(1)
    }
}));

function ActivityIndicator({ className, loading, title = 'loading..', ...rest }) {
    const classes = useStyles();
    
    return (
        <Backdrop className={classes.backdrop} open={loading}>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Logo className={classes.logo} />
                <Typography 
                    color="textSecondary"
                    variant="body1"
                    >{title}</Typography>
            </Box>
        </Backdrop>

    );
}

export default ActivityIndicator;
