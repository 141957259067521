import React from 'react';
import { Typography } from '@material-ui/core';

function ArrkInfo(props) {
    return (
        <Typography variant="body2" color="textSecondary">
            ARRK North America, Inc.{' '}
            <span style={{ fontWeight: 'bold' }}>(800) 735-2775</span>{' '}
        </Typography>
    );
}
export default ArrkInfo;
