import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import { ARRK_BASE_API } from 'src/constants';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const PROFILE_COMPLETENESS = '@account/profile-completeness';

export function login(email, password) {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOGIN_REQUEST
            });
            const user = await authService.loginWithEmailAndPassword(
                email,
                password
            );
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    user
                }
            });
        } catch (error) {
            dispatch({
                type: LOGIN_FAILURE
            });
            throw error;
        }
    };
}

export function loginToken(token) {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOGIN_REQUEST
            });
            const data = await authService.loginWithCode(token);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    user: data.user
                }
            });
        } catch (error) {
            dispatch({
                type: LOGIN_FAILURE
            });
            throw error;
        }
    };
}

export function setUserCompleteness(personalpct, companypct) {
    return (dispatch) =>
        dispatch({
            type: PROFILE_COMPLETENESS,
            payload: {
                personalpct,
                companypct,
            }
        });
}

export function setUserData(user) {
    return (dispatch) =>
        dispatch({
            type: SILENT_LOGIN,
            payload: {
                user
            }
        });
}

export function logout() {
    return async (dispatch) => {
        await authService.logout();
        dispatch({
                     type: LOGOUT
                 });
        window.location.replace('/login');


    };
}

export function updateProfile(update) {
    const request = axios.post(`${ARRK_BASE_API}/users/profile`, update);
    return (dispatch) => {
        request.then((response) => {
            if (response.data.data) {
                dispatch({
                    type: PROFILE_COMPLETENESS,
                    payload: {
                        personalpct: response.data.data.personalpct || 0,
                        companypct: response.data.data.companypct || 0
                    }
                });
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: response.data.data.user
                });
            }
        });
    };
}
