import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function ManagerGuard({ children }) {
    const { user } = useSelector((state) => state.account);

    if (user && user.role !== 'manager' && user.role !== 'admin') {
        return <Redirect to="/" />;
    }

    return children;
}

ManagerGuard.propTypes = {
    children: PropTypes.any
};

export default ManagerGuard;
