import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import viewUtils from 'src/utils/canview';

function PEGuard({ children }) {
    const account = useSelector((state) => state.account);
    if (!viewUtils.isPE(account.user) && !viewUtils.isPowerUser(account.user)) {
        return <Redirect to="/" />;
    }
    return children;
}

PEGuard.propTypes = {
    children: PropTypes.any
};

export default PEGuard;
