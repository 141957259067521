export const ARRK_BASE_API = process.env.REACT_APP_ARRK_BASE_API;
export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const QUOTE_STATUS = {
    draft: {
        text: 'DRAFT',
        color: 'warning'
    },
    partiallyshipped: {
        text: 'Partially Shipped',
        color: 'warning'
    },
    shipped: {
        text: 'Shipped',
        color: 'success'
    },
    sent: {
        text: 'Requested',
        color: 'warning'
    },
    working: {
        text: 'Pending',
        color: 'warning'
    },
    quoted: {
        text: 'Quoted',
        color: 'success'
    },
    accepted: {
        text: 'Accepted: awaiting order',
        color: 'primary'
    },
    planned: {
        text: 'Ordered',
        color: 'primary'
    },
    cancelled: {
        text: 'Cancelled',
        color: 'error'
    },
    cancelrequested: {
        text: 'Cancel Pending',
        color: 'error'
    },
    // Estimates.
    W: {
        text: 'Pending',
        color: 'warning'
    },
    O: {
        text: 'Ordered',
        color: 'primary'
    },
    Q: {
        text: 'Quoted',
        color: 'success'
    },
    P: {
        text: 'Ordered',
        color: 'primary'
    },
    S: {
        text: 'Shipped',
        color: 'success'
    }
};
export const INVOICE_STATUS = {
    paid: {
        text: 'PAID',
        color: 'success'
    },
    partial: {
        text: 'PARTIAL',
        color: 'warning'
    },
    unpaid: {
        text: 'UNPAID',
        color: 'error'
    }
};

/*
Urethane Casting

CNC Machining

3D Printing

Injection Molding

Design Model

Other

*/

export const USER_SOURCE = {
    google: "Google",
    socialmedia: "Social Media",
    printmedia: "Print Media",
    referral: "Referral",
    sales: "Sales Representative",
    tradeshow: "Trade Shows",
    other: "Other",
}

export const QUOTE_DELIVERABLES = [
    {
        id: 0,
        name: 'N/A'
    },
    {
        id: 1,
        name: 'Urethane Casting'
    },
    {
        id: 2,
        name: 'CNC Machining'
    },
    {
        id: 3,
        name: '3D Printing'
    },
    {
        id: 4,
        name: 'Injection Molding'
    },
    {
        id: 5,
        name: 'Design Model'
    },
    {
        id: 6,
        name: 'Other'
    }
];

export const TIME_RANGES = [
    {
        value: 'today',
        text: 'Today'
    },
    {
        value: 'yesterday',
        text: 'Yesterday'
    },
    {
        value: 'thisweek',
        text: 'This Week'
    },
    {
        value: 'lastweek',
        text: 'Last Week'
    },
    {
        value: 'thismonth',
        text: 'This Month'
    },
    {
        value: 'lastmonth',
        text: 'Last Month'
    },
    {
        value: 'thisyear',
        text: 'This Year'
    },
    {
        value: 'custom',
        text: "Custom Range"
    },
    {
        value: 'all',
        text: 'All'
    },

];
