import React from 'react';

import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function DeveloperGuard({ children }) {
    if (process.env.NODE_ENV !== 'development' && false) {
        return <Redirect to="/app" />;
    }
    return children;
}

DeveloperGuard.propTypes = {
    children: PropTypes.any
};

export default DeveloperGuard;
