import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
   const account = useSelector((state) => state.account);
    const  location  =  useLocation()
    console.log(location.pathname)
   if ( location.pathname.includes('/docs'))
    {
        return children
    }
   if (account.user ) {
        console.log("Redirecting on GuestGuard", account.user, location)
        return <Redirect to="/account" />;
    }

    return children;
}

GuestGuard.propTypes = {
    children: PropTypes.any
};

export default GuestGuard;
