import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Truncate from 'react-truncate';
import TimeAgo from 'react-timeago';

import {
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles
} from '@material-ui/core';

import {
    Bell as BellIcon,
    Package as PackageIcon,
    MessageCircle as MessageIcon,
    Truck as TruckIcon
} from 'react-feather';
import { getNotifications } from 'src/actions/notificationsActions';
import notificationsService from 'src/services/notificationsService';
import Pusher from 'pusher-js';

const iconsMap = {
    bell: BellIcon,
    package: PackageIcon,
    message: MessageIcon,
    shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main
    }
}));

function Notifications() {
    const classes = useStyles();
    const notifications = useSelector(
        (state) => state.notifications.notifications
    );
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        dispatch(getNotifications());
    }, [dispatch]);
    useEffect(() => {
        //TODO: read app_key from env
        const pusher = new Pusher(process.env.REACT_APP_ARRK_PUSHER_APP_KEY, {
            cluster: 'us3'
        });
        const channel = pusher.subscribe('arrk-updates');

        channel.bind('update-dashboard', function () {
            dispatch(getNotifications());
        });
    }, [dispatch]);
    const markAllAsRead = () => {
        let ids = '';
        notifications.forEach((n, i) => {
            if (i === notifications.length - 1) {
                ids = ids.concat(n.id);
                return;
            }
            ids = ids.concat(n.id, ',');
        });
        if (ids !== '') {
            const markAsRead = async () => {
                try {
                    await notificationsService.markAsRead(ids);
                    setOpen(false);
                } catch (error) {
                    alert('Error while marking notifications as read');
                }
            };
            markAsRead().finally(() => {
                setTimeout(() => {
                    dispatch(getNotifications());
                }, 1000);
            });
        }
    };

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton ref={ref} onClick={handleOpen}>
                    <Badge
                        color="secondary"
                        badgeContent={
                            notifications.length <= 10
                                ? notifications.length
                                : '10+'
                        }
                    >
                        <SvgIcon>
                            <BellIcon />
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                        Notifications
                    </Typography>
                </Box>
                {notifications && notifications.length === 0 ? (
                    <>
                        <Box p={2}>
                            <Typography variant="h6" color="textPrimary">
                                There are no notifications
                            </Typography>
                        </Box>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button
                                color="primary"
                                component={RouterLink}
                                size="small"
                                to="/app/management/notifications"
                                onClick={() => setOpen(false)}
                            >
                                View All
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <List className={classes.list} disablePadding>
                            {notifications &&
                                notifications.map((notification) => {
                                    const Icon =
                                        iconsMap[
                                            notification.icon !== ''
                                                ? notification.icon
                                                : 'bell'
                                        ] || BellIcon;

                                    return (
                                        <ListItem
                                            className={classes.listItem}
                                            component={RouterLink}
                                            divider
                                            onClick={() => setOpen(false)}
                                            key={notification.id}
                                            to={`/app/management/notifications/${notification.id}`}
                                        >
                                            <ListItemAvatar>
                                                <Avatar
                                                    className={classes.icon}
                                                >
                                                    <SvgIcon
                                                        fontSize="small"
                                                        style={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                        <Icon />
                                                    </SvgIcon>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={notification.title}
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'textPrimary'
                                                }}
                                                secondary={
                                                    <>
                                                        <Truncate
                                                            lines={3}
                                                            ellipsis={
                                                                <span>...</span>
                                                            }
                                                        >
                                                            {
                                                                notification.description
                                                            }
                                                        </Truncate>
                                                        <br />
                                                        <Typography
                                                            variant="caption"
                                                            color="inherit"
                                                            align="right"
                                                        >
                                                            <TimeAgo
                                                                date={
                                                                    notification.created
                                                                }
                                                            />
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button size="small" onClick={markAllAsRead}>
                                Mark all as read
                            </Button>
                            <Button
                                onClick={() => setOpen(false)}
                                color="primary"
                                component={RouterLink}
                                size="small"
                                to="/app/management/notifications"
                            >
                                View All
                            </Button>
                        </Box>
                    </>
                )}
            </Popover>
        </>
    );
}

export default Notifications;
