import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
    Package as PackageIcon,
    Truck as TruckIcon,
    User as UserIcon,
    PieChart as PieChartIcon,
    PlusCircle as PlusIcon
} from 'react-feather';
const UserNavConfig = [
    {
        canview: ['user'],
        subheader: 'Main',
        items: [
            {
                title: 'Dashboard',
                icon: PieChartIcon,
                href: '/app/dashboard'
            },
            {
                title: 'New Quote Request',
                icon: PlusIcon,
                href: '/app/requests/new'
            }
        ]
    },
    {
        canview: ['user'],
        subheader: 'Management',
        items: [
            {
                title: 'Quotes',
                icon: PackageIcon,
                href: '/app/management/requests'
            },
            {
                title: 'Order History',
                icon: TruckIcon,
                href: '/app/management/orders'
            },
            {
                title: 'Invoices',
                icon: ReceiptIcon,
                href: '/app/management/invoices'
            }
        ]
    },
    {
        canview: ['user', 'admin', 'pe', 'manager'],
        subheader: 'Settings',
        href: '/app/pages',
        items: [
            {
                title: 'Account',
                href: '/account',
                icon: UserIcon
            }
        ]
    }
];

export default UserNavConfig;
