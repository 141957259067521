import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';
import axios from 'src/utils/axios';
import { ARRK_BASE_API } from 'src/constants';

const SettingsContext = createContext();

const defaultSettings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT
};

export function SettingsProvider({ settings, children }) {
    const [currentSettings, setCurrentSettings] = useState(
        settings || defaultSettings
    );

    const handleSaveSettings = (updatedSettings = {}) => {
        const mergedSettings = _.merge({}, currentSettings, updatedSettings);

        setCurrentSettings(mergedSettings);
        storeSettings(mergedSettings);
    };
    useEffect(() => {
        axios
            .get(`${ARRK_BASE_API}/countries`, {})
            .then((response) => {
                localStorage.setItem(
                    'countries',
                    response.data.data ? JSON.stringify(response.data.data) : ''
                );
            })
            .catch((error) => {
                //console.log(error);
            });
        localStorage.setItem('states', []);
        // Get Indudsitres set
        axios
            .get(`${ARRK_BASE_API}/industries`, {})
            .then((response) => {
                localStorage.setItem(
                    'industries',
                    response.data.data ? JSON.stringify(response.data.data) : ''
                );
            })
            .catch((error) => {
                //console.log(error);
            });
    }, []);

    useEffect(() => {
        document.dir = currentSettings.direction;
        document.ARRK_AZURE_TENANT = process.env.REACT_APP_ARRK_AZURE_TENANT;
        document.ARRK_AZURE_LOGINFLOW =
            process.env.REACT_APP_ARRK_AZURE_LOGINFLOW;
        document.ARRK_AZURE_SIGNUPFLOW =
            process.env.REACT_APP_ARRK_AZURE_SIGNUPFLOW;
        document.ARRK_AZURE_CLIENTID =
            process.env.REACT_APP_ARRK_AZURE_CLIENTID;
        document.ARRK_AZURE_RESET = process.env.REACT_APP_ARRK_RESET;
        //console.log(document.ARRK_AZURE_RESET);

        document.ARRK_AZURE_REDIRECTLOGINURI =
            process.env.REACT_APP_ARRK_AZURE_REDIRECTLOGINURI;
        document.ARRK_AZURE_REDIRECTLOGOUTURI =
            process.env.REACT_APP_ARRK_AZURE_REDIRECTLOGOUTURI;
        document.ARRK_AZURE_REDIRECTSIGNUPURI =
            process.env.REACT_APP_ARRK_AZURE_REDIRECTSIGNUPURI;
    }, [currentSettings]);

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                saveSettings: handleSaveSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}
SettingsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
